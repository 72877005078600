p.mindmaps-wrapper {
    margin: 0;
}

svg.mindmaps {
    width: 80%;
    /*noinspection CssUnresolvedCustomProperty*/
    border: 2px dashed var(--mind-maps-border-color,#efefef);
    border-radius: 4px;
    background: url(c9432de6d041069b20ed8c733b04a6fe.png) repeat center center;
    background-size: 20px 20px;
}